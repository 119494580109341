import React from "react";
import { Link } from "react-router-dom";
import offer from "../../../assets/img/more-service2.jpg";

const SpecialOffer = () => {
  const items = [
    "Consectetur Adipiscing",
    "Eget Euismod Orci",
    "Ullamcorper Leo",
    "Sit dicit alienum",
    "Eget Euismod Orci",
    "Consectetur Adipiscing",
    "Eget Euismod Orci",
    "Ullamcorper Leo",
    "Sit dicit alienum",
    "Eget Euismod Orci",
  ];

  return (
    <>
      <div className="SpecialOffer sectionSpace bgGray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="offerImg">
                <Link to={"/service-details"}>
                  <img src={offer} alt="Special Offer" className="img-fluid" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="offerContent">
                <h3>Our Special Offers</h3>
                <p>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, te consec tetur, adipisci velit, sed quia non numquam
                  eius modi tempora incidunt ut labore et dolore magnam aliquam.
                </p>
                <ul>
                  {items.map((item, i) => {
                    return <li key={i}>{item}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialOffer;
