import React, { useEffect } from "react";
import TopHeader from "./top-header/TopHeader";
import SearchBox from "./search-box/SearchBox";
import { isMobile } from "react-device-detect";

import "./Header.css";
import Menus from "./menu/Menus";
import { useState } from "react";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import { base_url } from "../../server";

function Header({ changeLang }) {
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const baseUrl = base_url();
  const fixedHeader = () => {
    if (window.scrollY >= 80) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  window.addEventListener("scroll", fixedHeader);

  const [state, setState] = useState({
    front_top_message: "",
    icon: "",
  });

  const onMenuButtonClicked = () => {
    if (isMobile) {
      setShowMenu(!showMenu);
    }
  };
  const [isClass, setIsClass] = useState("");
  const location = useLocation();
  useEffect(() => {
    console.log("location", location.pathname);

    if (location.pathname === "/") {
      setIsClass("home");
    } else {
      setIsClass("");
    }
  }, [location.pathname]);
  return (
    <>
      {/* <button type="button" onClick={()=>{changeLang('en')}}>EEE</button>
                    <button type="button" onClick={()=>{changeLang('de')}}>ARR</button> */}
      <header className={`${isSticky ? "sticky active" : `sticky ${isClass}`}`}>
        <TopHeader state={state} changeLange={changeLang} />
        <SearchBox val={state} showMenu={onMenuButtonClicked} />
        {!isMobile && (
          <section className="navbarArea">
            <div className="abaris-nav">
              <div className="container">
                <div className="menuCard">
                  <div className="allCategoryDrop">
                    <span>categories</span>
                    <i className="ri-menu-line"></i>

                    <div className="categoryList">
                      <ul>
                        <li>
                          <Link to="/products">Baby bottles</Link>
                        </li>
                        <li>
                          <Link to="/products">Breastfeeding Accessories</Link>
                        </li>
                        <li>
                          <Link to="/products">Bibs & Cloths</Link>
                        </li>
                        <li>
                          <Link to="/products">Teethers & Pacifiers</Link>
                        </li>
                        <li>
                          <Link to="/products">Feeding Essentials</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </section>
        )}
      </header>
    </>
  );
}

export default Header;
