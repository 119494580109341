import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import ServiceDetail from "../../components/service-detail/ServiceDetail";

const ServiceDetailPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb title="Service Detail" t={t} />
      <ServiceDetail />
    </>
  );
};

export default ServiceDetailPage;
