import React from "react";
import offer from "../../assets/img/more-service2.jpg";
import { Link } from "react-router-dom";
const listData = [
  {
    id: 1,
    title: "Project Management",
    content:
      "Nor is there any one who wants to gain pain because it is pain itself, and it constrains and frightens you, but because such times do not never occur in which he seeks some great pleasure through labor and pain. Nor, moreover, is there anyone who loves pain because it is pain, follows you, wants to go fishing, but because such times never occur when he seeks some great pleasure through labor and pain.",
  },
  {
    id: 2,
    title: "Reporting and Cost Control",
    content:
      "Nor is there any one who wants to gain pain because it is pain itself, and it constrains and frightens you, but because such times do not never occur in which he seeks some great pleasure through labor and pain. Nor, moreover, is there anyone who loves pain because it is pain, follows you, wants to go fishing, but because such times never occur when he seeks some great pleasure through labor and pain.",
  },
  {
    id: 3,
    title: "Coordination Process",
    content:
      "Nor is there any one who wants to gain pain because it is pain itself, and it constrains and frightens you, but because such times do not never occur in which he seeks some great pleasure through labor and pain.",
  },
  {
    id: 4,
    title: "Personal Sentiments",
    content:
      "I do not want to be a foreigner. There is nothing to be desired in mine. The dangers of Euripides.",
  },
  {
    id: 5,
    title: "Commissioning and Project Closeout",
    content:
      "Nor is there any one who wants to gain pain because it is pain itself, and it constrains and frightens you, but because such times do not never occur in which he seeks some great pleasure through labor and pain.",
  },
  {
    id: 6,
    title: "Personal Sentiments (Repeat)",
    content:
      "I do not want to be a foreigner. There is nothing to be desired in mine. The dangers of Euripides.",
  },
];

const serve = [
  {
    id: 1,
    title: "Emergency Services",
    content:
      "Nor, moreover, does anyone who wants to gain access to the internet because it is a pain in the ass.",
  },
  {
    id: 2,
    title: "Guaranteed Works",
    content:
      "Nor, moreover, does anyone who wants to gain access to the internet because it is a pain in the ass.",
  },
  {
    id: 3,
    title: "Fast Services",
    content:
      "Nor, moreover, does anyone who wants to gain access to the internet because it is a pain in the ass.",
  },
  {
    id: 4,
    title: "Affordable Price",
    content:
      "Nor, moreover, does anyone who wants to gain access to the internet because it is a pain in the ass.",
  },
  {
    id: 5,
    title: "Highly Trained Staff",
    content:
      "Nor, moreover, does anyone who wants to gain access to the internet because it is a pain in the ass.",
  },
  {
    id: 6,
    title: "Free Consultation",
    content:
      "Nor, moreover, does anyone who wants to gain access to the internet because it is a pain in the ass.",
  },
];

const ServiceDetail = () => {
  return (
    <>
      <div className="serviceDetail sectionSpace">
        <div className="container">
          <div className="weOffer">
            <div className="row">
              <div className="col-lg-6">
                <div className="offerImg">
                  <Link to={"/service-details"}>
                    <img
                      src={offer}
                      alt="Special Offer"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="offerContent">
                  <h3>What We Offer</h3>
                  <p>
                    Nor, moreover, is there any one who wants to obtain the pain
                    itself because he loves the pain, it follows you, but not
                    because he never wants to obtain it, but because he does
                    not.
                  </p>
                  <p>
                    Nor, moreover, is there any one who wants to gain pain
                    because it is pain itself, because it is pain, it is a
                    consequence of you, but because such times do not never
                    occur when he seeks some great pleasure through labor and
                    pain. Nor, moreover, is there any one who wants to gain pain
                    because it is pain itself, because it is pain, it is a
                    consequence of you, but because such times do not never
                    occur when he seeks some great pleasure through labor and
                    pain.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="listData sectionSpace bgGray">
        <div className="container">
          <div className="row">
            {listData.map((item) => {
              return (
                <div className="col-lg-6 col-md-6" key={item.id}>
                  <div className="listCard">
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="listData sectionSpace">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="fisherman-content">
                <h3>Specific Offers of The Service </h3>
              </div>
            </div>
            {serve.map((item) => {
              return (
                <div className="col-lg-4 col-md-4" key={item.id}>
                  <div className="listCard">
                    <div className="icon">{item.id}</div>
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetail;
