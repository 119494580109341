import React from "react";
import FeaturedServices from "./featured-services/FeaturedServices";
import SpecialOffer from "./special-offer/SpecialOffer";

const Service = () => {
  return (
    <>
      <section className="servicePage sectionSpace">
        <FeaturedServices />
        <SpecialOffer />
      </section>
    </>
  );
};

export default Service;
