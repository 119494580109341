import React from "react";

import home2 from "../../../assets/img/ab.jpg";

import {
  GiChestnutLeaf,
  GiFruitBowl,
  GiTomato,
  GiFertilizerBag,
} from "react-icons/gi";
import { Link } from "react-router-dom";

import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList sectionPD bgGray">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="aboutImg">
                <img src={home2} alt="About" className="img-fluid" />
                <span>Handmade wooden wonders for you</span>
              </div>
            </div>
            <div className="col-md-7">
              <div className="aboutWooden">
                <span>colors</span>
                <h2>shades of elegance </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                  corporis unde consectetur distinctio labore similique
                  necessitatibus magni omnis ipsam harum deserunt sint quia
                  officia natus, cupiditate doloribus, neque atque vitae.
                </p>
                <ul>
                  <li>Natural materials</li>
                  <li>Modern design</li>
                </ul>
                <Link to={"/"}>read more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
