import React from "react";
import BasementPlumbing from "../../../assets/img/mona/BasementPlumbing.jpg";
import BathroomPlumbing from "../../../assets/img/mona/BathroomPlumbing.jpg";
import KitchenPlumbing from "../../../assets/img/mona/KitchenPlumbing.jpg";
import gaslineman from "../../../assets/img/mona/gaslineman.jpg";
import waterlineman from "../../../assets/img/mona/waterlineman.jpg";
import drainservice from "../../../assets/img/mona/drainservice.jpg";
import { Link } from "react-router-dom";

const plumbingServices = [
  {
    id: 1,
    title: "Customize Doors",
    url: BasementPlumbing,
    description:
      "Neque porro quisquam est, qui dolor em ipsum quia dolor sit amet, consec tetur, adipisci velit, sed quia non.",
  },
  {
    id: 2,
    title: "Customised Frames",
    url: BathroomPlumbing,
    description:
      "Neque porro quisquam est, qui dolor em ipsum quia dolor sit amet, consec tetur, adipisci velit, sed quia non.",
  },
  {
    id: 3,
    title: "Kitchen Cabinets",
    url: KitchenPlumbing,
    description:
      "Neque porro quisquam est, qui dolor emr ipsum quia dolor sit amet, consec tetur is adipisci velit, sed quia non num.",
  },
  {
    id: 4,
    url: gaslineman,
    title: "All type of Wooden Work",
    description:
      "Neque porro quisquam est, qui dolor emr ipsum quia dolor sit amet, consec tetur is adipisci velit, sed quia non num.",
  },
  {
    id: 5,
    url: drainservice,
    title: "Drain Cleaning",
    description:
      "Neque porro quisquam est, qui dolor emr ipsum quia dolor sit amet, consec tetur is adipisci velit, sed quia non num.",
  },
  {
    id: 6,
    url: waterlineman,
    title: "Water Line Repair",
    description:
      "Neque porro quisquam est, qui dolor emr ipsum quia dolor sit amet, consec tetur is adipisci velit, sed quia non num.",
  },
];

const FeaturedServices = () => {
  return (
    <>
      <div className="featuredService">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="fisherman-content">
                <h3>Featured Services</h3>
              </div>
            </div>
            {plumbingServices.map((item) => {
              return (
                <div className="col-lg-4 col-md-4" key={item.id}>
                  <div className="serviceCard">
                    <img
                      src={item.url}
                      alt={item.title}
                      className="img-fluid"
                    />
                    <div className="text">
                      <h5><Link to={"/service-detail"}>{item.title}</Link></h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedServices;
