import React from "react";
import Service from "../../components/services/Service";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

const ServicePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb title="Service List" t={t} />
      <Service />
    </>
  );
};

export default ServicePage;
